import * as React from 'react';
import Layout from '../components/Layout';
import Research from '../components/sections/Research';
import MuseumEducation from '../components/sections/MuseumEducation';
import Curatorial from '../components/sections/Curatorial';
import Contact from '../components/sections/Contact';
import {graphql} from 'gatsby';

export default function IndexPage(props) {
  return (
    <Layout>
      <div>
        <Research jackBushImage={props.data.jackBushImage.childImageSharp} />
        <MuseumEducation
          outreachImage={props.data.outreachImage.childImageSharp}
        />
        <Curatorial
          lookingInwardImage={props.data.lookingInwardImage.childImageSharp}
        />
        <Contact contactImage={props.data.contactImage.childImageSharp} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query SiteQuery {
    jackBushImage: file(
      sourceInstanceName: {eq: "images"}
      name: {eq: "jack-bush-photo-shoot"}
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    outreachImage: file(
      sourceInstanceName: {eq: "images"}
      name: {eq: "outreach"}
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lookingInwardImage: file(
      sourceInstanceName: {eq: "images"}
      name: {eq: "looking-inward"}
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contactImage: file(
      sourceInstanceName: {eq: "images"}
      name: {eq: "antonia-at-lpch"}
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
