// @flow

import * as React from 'react';
import WorksCitedEntry from '../../WorksCitedEntry';
import ContentWrapper from '../../ContentWrapper';
import Section from '../../Section';
import styles from './styles.module.css';

type Props = {|
  jackBushImage: any, // TODO: Type this.
|};

const IMAGE_CAPTION = (
  <>
    Posing after a day-long shoot of Jack Bush paintings at the Miriam Shiell
    Fine Art Gallery in Toronto, ON. Photograph by Michael Cullen.
  </>
);

export default (props: Props) => (
  <ContentWrapper>
    <Section image={{fluid: props.jackBushImage.fluid, caption: IMAGE_CAPTION}}>
      <h1 id="research">Research</h1>
      <p>
        Antonia earned her Masters degree in Art History from the University of
        Toronto with an emphasis on modern and contemporary art. Her art
        historical interests range from Postwar German art to British Modernism.
        However, she has a particular passion for North American Colorfield
        paintings, fostered during her time as a research assistant on the
        forthcoming{' '}
        <a href="http://jackbush.org/">Jack Bush Catalogue Raisonné</a>. Her
        research has taken on a variety of different forms, from public speaking
        engagements at museums and universities to articles in peer-reviewed
        journals and art magazines, including:
      </p>
      <ul>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Eric Gill and Jacob Epstein: Carving the Way to a Masculine (Pro)creation"
            publications={['Interdisciplinary Humanities']}
            url="https://www.masader.om/eds/detail?db=hsi&an=137660620&isbn=10566139"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="African Art at The Kreeger Museum: Validating a Collection and Its Historic Stakeholders"
            publications={[
              'Collections: A Journal for Museum and Archives Professionals',
            ]}
            url="https://journals.sagepub.com/doi/pdf/10.1177/155019061801400104"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="The Experimental Art of Noel Harding: At the Intersection of Nature and Technology"
            publications={['Aesthetic Investigations']}
            url="https://www.aestheticinvestigations.eu/index.php/journal/article/view/106"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Jean Dubuffet & Art Brut: The Creation of an Avant-garde Identity"
            publications={['Platform: Journal of Theater and Performing Arts']}
            url="https://www.royalholloway.ac.uk/media/5451/04_jean_dubuffet_and_art_brut_depanatretter.pdf"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Willem Boshoff's Visual Lists: A Personal Plea for Cultural Preservation"
            publications={[
              'African Arts',
              'Seismopolite: Journal of Art and Politics',
            ]}
            url="http://www.seismopolite.com/willem-boshoff-s-visual-lists-a-personal-plea-for-cultural-preservation"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Paintings as Propaganda: Blending Patriotism with Art"
            publications={['Lincoln Humanities Journal']}
            url="https://www.lincoln.edu/_files/academics/Lincoln-Humanities-Journal-Vol-4-2016.pdf"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Peeling Back Robert W. Newmann - Narrative Portfolio"
            publications={['Droste Effect Magazine']}
            url="http://www.drosteeffectmag.com/wp-content/uploads/2016/08/Bulletin_03.pdf"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Richard Long's Passage as Line: Measuring Toward the Horizon"
            publications={['Iowa Journal of Cultural Studies']}
            url="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=AALESywAAAAJ&citation_for_view=AALESywAAAAJ:qjMakFHDy7sC"
          />
        </WorksCitedListItem>
        <WorksCitedListItem>
          <WorksCitedEntry
            title="Gerhard Richter and Sigmar Polke: Painting the Postwar German Experience"
            publications={[
              'European Studies Conference Selected Proceedings, Art and Music',
            ]}
            url="https://www.academia.edu/6954011/Gerhard_Richter_and_Sigmar_Polke_Painting_the_Postwar_German_Experience"
          />
        </WorksCitedListItem>
      </ul>
    </Section>
  </ContentWrapper>
);

function WorksCitedListItem(props) {
  return <li className={styles.WorksCitedListItem}>{props.children}</li>;
}
