// @flow

import * as React from 'react';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';
import Hero from '../Hero';
import Border from '../Border';
import QuickNav from '../QuickNav';

// Imported for side-effects (gasp!)
// eslint-disable-next-line no-unused-vars
import styles from './styles.module.css';

type Props = {
  children: Function,
  data: Object, // TODO: Fix this
};

const TITLE = 'Antonia Dapena-Tretter';
const SITE_URL = 'https://maybeorange.com';
const DESCRIPTION =
  'The professional website of Antonia Dapena-Tretter, a non-profit arts professional in the San Francisco Bay Area.';

export default function Layout(props: Props) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div>
          <Helmet>
            <title>{TITLE}</title>
            <meta charSet="utf-8" />
            <link rel="canonical" href={SITE_URL} />
            <meta name="description" content={DESCRIPTION} />
            <meta
              name="image"
              content={`${SITE_URL}${data.openGraphImage.childImageSharp.fixed.src}`}
            />
            <meta property="og:url" content={SITE_URL} />
            <meta property="og:title" content={TITLE} />
            <meta property="og:description" content={DESCRIPTION} />
            <meta
              property="og:image"
              content={`${SITE_URL}${data.openGraphImage.childImageSharp.fixed.src}`}
            />
          </Helmet>
          <Hero image={data.heroImage.childImageSharp} />
          <QuickNav />
          {props.children}
          <Border />
        </div>
      )}
    />
  );
}

const query = graphql`
  query TemplateQuery {
    heroImage: file(
      sourceInstanceName: {eq: "images"}
      name: {eq: "antonia-headshot"}
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    openGraphImage: file(
      sourceInstanceName: {eq: "images"}
      name: {eq: "open-graph-image"}
    ) {
      childImageSharp {
        fixed(width: 1200) {
          src
        }
      }
    }
  }
`;
