// @flow

import * as React from 'react';
import ContentWrapper from '../ContentWrapper';
import Section from '../Section';

type Props = {|
  lookingInwardImage: any, // TODO: Type this.
|};

const IMAGE_CAPTION = (
  <>
    Community Access Exhibition <i>Looking Inward: Painting a Journey</i> at The
    Walt Disney Family Museum. Photo by Frank Anzalone.
  </>
);

export default (props: Props) => (
  <ContentWrapper>
    <Section
      image={{fluid: props.lookingInwardImage.fluid, caption: IMAGE_CAPTION}}
    >
      <h1 id="curatorial">Curatorial</h1>
      <p>
        Believing in the power of art to heal,
        Antonia currently holds the position of Art Curator at{' '}
        <a href="https://art.stanfordchildrens.org">
          Stanford Children's Health
        </a>
        . In this role, she oversees all acquisitions, installations, temporary
        displays, and conservation measures for a robust permanent collection of
        art.
      </p>
      <p>
        Antonia co-curated her first show at The Trout Gallery as a part of her{' '}
        <a href="http://chronicles.dickinson.edu/studentwork/indian/indian.pdf">
          BA thesis project
        </a>
        . Her exhibition experience continued at The Kreeger Museum where she
        served as the registrar for shows such as{' '}
        <a href="https://www.kreegermuseum.org/exhibitions/event/Tom-Wesselmann-DRAWS-2011-04-08">
          Tom Wesselmann Draws
        </a>{' '}
        and{' '}
        <a href="https://kreegermuseum.org/images/21498_KK%20Catalog_Txt.pdf">
          Kentridge and Kudryashov: Against the Grain
        </a>
        .
      </p>
      <p>
        Her community engagement work merged with her exhibition experience when
        she curated the first of many Community Access Exhibitions at The Walt
        Disney Family Museum. Today she is particularly proud of the{' '}
        <a href="https://www.waltdisney.org/press-room/fresh-starts">
          Fresh Starts
        </a>{' '}
        initiative and corresponding exhibition which showcased original artwork
        made by students in the juvenile justice system.
      </p>
    </Section>
  </ContentWrapper>
);
