import * as React from 'react';
import Img from 'gatsby-image';
import classnames from 'classnames';
import styles from './styles.module.css';

type Props = {|
  image: {|
    fluid: any,
    caption: React.Node,
    location?: 'left' | 'right',
  |},
|};

export default function Section(props: Props) {
  const location = props.image.location || 'left';
  const figureClassNames = classnames(styles.figure, {
    [styles.left]: location === 'left',
    [styles.right]: location === 'right',
  });
  return (
    <section className={styles.section}>
      <figure className={figureClassNames}>
        <div>
          <Img fluid={props.image.fluid} />
        </div>
        <figcaption className={styles.figureCaption}>
          {props.image.caption}
        </figcaption>
      </figure>
      <div className={styles.content}>{props.children}</div>
    </section>
  );
}
