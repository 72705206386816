import * as React from 'react';
import Img from 'gatsby-image';
import ContentWrapper from '../ContentWrapper';
import styles from './styles.module.css';

type Props = {|
  image: Object, // FIXME
|};

export default function Hero(props: Props) {
  return (
    <ContentWrapper>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.logoType}>
            <h1 className={styles.name}>
              <svg viewBox="0 0 444.5 310">
                <text x="0" y="80">
                  Antonia{' '}
                </text>
                <text x="-10" y="190">
                  Dapena-
                </text>
                <text x="0" y="300">
                  Tretter
                </text>
              </svg>
            </h1>
            <h2 className={styles.jobTitle}>Nonprofit Arts Professional</h2>
          </div>
          <div className={styles.image}>
            <Img fluid={props.image.fluid} />
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}
