// @flow

import * as React from 'react';
import styles from './styles.module.css';

export default () => (
  <nav className={styles.container}>
    <strong>Jump to…</strong>
    <ul>
      <li><a href="#research">Research</a></li>
      <li><a href="#museum-education">Museum Education</a></li>
      <li><a href="#curatorial">Curatorial</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
  </nav>
);
