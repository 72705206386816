// @flow

import * as React from 'react';
import ContentWrapper from '../ContentWrapper';
import Section from '../Section';

type Props = {|
  contactImage: any, // FIXME: Figure out how to get a Flow type for this.
|};

const IMAGE_CAPTION = (
  <>
    Antonia with <i>Joie de vivre</i> by Jonathan Darmon (2016) at Lucile
    Packard Children's Hospital Stanford. Photo by Douglas L. Peck.
  </>
);

export default (props: Props) => (
  <div
    style={{
      paddingTop: '30px',
      overflow: 'hidden', // Wrap the Section margin. Is this what we want?
    }}
  >
    <ContentWrapper>
      <Section
        image={{
          fluid: props.contactImage.fluid,
          caption: IMAGE_CAPTION,
          location: 'right',
        }}
      >
        <h1 id="contact">Contact</h1>
        <p>
          While Antonia lives in the San Francisco Bay Area, she is eager to
          collaborate with like-minded professionals around the globe. Reach out
          by <a href="mailto:antonia@maybeorange.com">email</a>, follow her on{' '}
          <a href="https://kreegermuseum.academia.edu/AntoniaDapenaTretter">
            Academia.edu
          </a>
          , or connect with her on{' '}
          <a href="https://www.linkedin.com/in/maybeorange/">LinkedIn</a> for
          more professional updates.
        </p>
      </Section>
    </ContentWrapper>
  </div>
);
