// @flow

import * as React from 'react';
import ContentWrapper from '../ContentWrapper';
import Section from '../Section';

type Props = {|
  outreachImage: any, // TODO: Type this.
|};

const IMAGE_CAPTION = (
  <>
    Outreach with senior participant at Alzheimer's Services of the East Bay in
    Hayward, CA. Photo by Frank Anzalone.
  </>
);

export default (props: Props) => (
  <ContentWrapper>
    <Section
      image={{
        fluid: props.outreachImage.fluid,
        caption: IMAGE_CAPTION,
        location: 'right',
      }}
    >
      <h1 id="museum-education">Museum Education</h1>
      <p>
        Antonia is passionate about sharing art with the public through
        educational and cross-disciplinary learning opportunities. In this
        capacity, she has held managerial positions at institutions like{' '}
        <a href="https://www.kreegermuseum.org/">The Kreeger Museum</a> and{' '}
        <a href="http://waltdisney.org/">The Walt Disney Family Museum</a>, and
        has exposed thousands of students, educators, and underserved
        populations to the expressive possibilities of art-making and animation.
      </p>
      <p>
        Highlights from her time as a museum educator include introducing young
        students from D.C. public schools to classical music by the acclaimed{' '}
        <a href="http://storybank.dccollaborative.org/post/119277726419/member-feature-the-kreeger-museum-embarks-on-a">
          American Chamber Players
        </a>{' '}
        and being invited to co-lead{' '}
        <a href="https://www.youtube.com/watch?v=zVPBvckL6s8">
          a keynote session presentation at the Fall 2016 CUE Conference
        </a>
        .
      </p>
      <p>
        Her strong belief in the importance of arts access fuels her ongoing
        work as a member of the Board of Directors at the{' '}
        <a href="http://niadart.org/">NIAD Art Center</a>. Serving in roles from
        secretary to president, Antonia supports NIAD in its mission to redefine
        contemporary art by creating a progressive art studio for practicing
        artists of all abilities.
      </p>
    </Section>
  </ContentWrapper>
);
