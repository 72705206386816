// @flow

import * as React from 'react';

type Props = {|
  title: string,
  publications: Array<string>,
  url: string,
|};

export default function WorksCitedEntry(props: Props) {
  const publications = intersperse(
    props.publications.map((publication, i) => <em key={i}>{publication}</em>),
    ' and ',
  );
  return (
    <div>
      <a href={props.url}>"{props.title}"</a> in {publications}
    </div>
  );
}

const intersperse = (arr: Array<React.Node>, sep: React.Node) =>
  arr.reduce((a, v) => [...a, v, sep], []).slice(0, -1);
