// @flow

import * as React from 'react';

const style = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  // border: '5px solid #f34924',
  border: '5px solid rgb(255, 144, 46)',
  pointerEvents: 'none',
};

export default () => <div style={style} />;
